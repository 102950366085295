import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

const routes = [
  {
    path: '/:param?',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/basic-information/:param?',
    name: 'BasicInformation',
    component: () => import('../views/BasicInformation.vue')
  },
  {
    path: '/academic-results/:param?',
    name: 'AcademicResults',
    component: () => import('../views/AcademicResults.vue')
  },
  {
    path: '/video-presentation/:param?',
    name: 'VideoPresentation',
    component: () => import('../views/VideoPresentation.vue')
  },
  {
    path: '/recommendations-teamworks/:param?',
    name: 'RecommendationsTeamworks',
    component: () => import('../views/RecommendationsTeamworks.vue')
  },
  {
    path: '/payment-fee/:param?',
    name: 'PaymentFee',
    component: () => import('../views/PaymentFee.vue')
  },
  {
    path: '/confirmation-process',
    name: 'ConfirmationProcess',
    component: () => import('../views/ConfirmationProcess.vue')
  },
  {
    path: '/help',
    name: 'Help',
    component: () => import('../views/Help.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if(to.name !== 'PaymentFee' && to.name !== 'ConfirmationProcess' && to.name !== 'Help' && to.name !== 'Home' && store.state.msalConfig.accessToken === null) next('/')
  next();
})

export default router
