export default {
  namespaced: true,
  state: {
    status: false,
    grades: {},
    document: {}
  },
  mutations: {
    setGrades(state, data) {
      if (data.last_term_grades == null)
        { data.last_term_grades = {'name':null,'file':null, 'delete':false};} 
      state.grades = data
    },
    setDocument(state, data) {
      if (data == null)
        { data = {'name':null,'file':null, 'delete':false};} 
      state.document = data
    },
    resetData(state){
      state.grades = {};
      state.document = {};
    }
  },
  actions: {},
}