import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createI18n } from 'vue-i18n'

import 'bootstrap'

import axios from 'axios'
import VueAxios from 'vue-axios'
import { VuelidatePlugin } from '@vuelidate/core';
import VCalendar from 'v-calendar';

const langs = () => {
  const langs = require.context('./assets/langs', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  langs.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if(matched && matched.length > 1) {
      const lang = matched[1]
      messages[lang] = langs(key)
    }
  })
  return messages
}

const app = createApp(App)
const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'es',
  messages: langs(),
  warnHtmlInMessage: 'off' // disable of the Detected HTML in message
})

app.use(store)
app.use(router)
app.use(i18n)
app.use(VueAxios, axios)
app.use(VuelidatePlugin)
app.use(VCalendar, {})
app.mount('#app')
app.config.errorHandler = function (err, vm, info) {
  console.log(err);
  console.log(`Error: ${err.toString()}\nInfo: ${info}`);
}