export default {
  namespaced: true,
  state: {
    status: false,
    presentation: null
  },
  mutations: {
    setPresentation(state, data) {
      state.presentation = data
    },
    resetData(state){
      state.presentation = null;
    }
  },
  actions: {},
}