export default {
  namespaced: true,
  state: {
    accessToken: null,
    account: {},
    authApp: {
      auth: {
        clientId: process.env.VUE_APP_REDIRECT_CLIENT,
        authority: process.env.VUE_APP_REDIRECT_AUTHORITY,
        knownAuthorities: [process.env.VUE_APP_REDIRECT_KNOWN_AUTHORITY],
        redirectUri: process.env.VUE_APP_REDIRECT_URI,
        postLogoutRedirectUri: process.env.VUE_APP_REDIRECT_URI
        //redirectUri: "https://crm-gcadmission.dev.ie.edu/",
      },
      cache: {
        cacheLocation: "sessionStorage"
      }
    },
    loginRequest: {
      scopes: ["openid", ...[process.env.VUE_APP_REDIRECT_SCOPES]]
    }
  },
  mutations: {
    setAccessToken(state, data) {
      state.accessToken = data
      sessionStorage.setItem("accessToken", data);
    },
    setAccount(state, data) {
      state.account = data
    },
    resetData(state){
      state.accessToken = null;
      state.account = {};
    }
  },
  actions: {
  },
}