export default {
  namespaced: true,
  state: {
    status: false,
//    are_you_working: {},
    extracurricular_activities: [],
    international_experience: [],
    good_english: {}
  },
  mutations: {
//    setAreYouWorking(state, data) {
//      if (data.documentation == null)
//      { data.documentation = {'name':null,'file':null, 'delete':false};}
 
//      state.are_you_working = data
//    },
    setGoodEnglish(state, data) {
      if (data.additional_documentation == null)
        { data.additional_documentation = {'name':null,'file':null, 'delete':false};} 
      state.good_english = data
    },
    setExtracurricularActivities(state, data) {
      state.extracurricular_activities = data
    },
    setInternationalExperiences(state, data) {
      state.international_experience = data
    },
    resetData(state){
//      state.are_you_working = {};
      state.extracurricular_activities = [];
      state.international_experience = [];
      state.good_english = {};
    }
  },
  actions: {},
}