import { createStore } from 'vuex'
import msalConfig from './modules/msalConfig'
import BasicInformation from './modules/BasicInformation'
import AcademicResults from './modules/AcademicResults'
import VideoPresentation from './modules/VideoPresentation'
import RecommendationsTeamworks from './modules/RecommendationsTeamworks'

export default createStore({
  modules: {
    msalConfig,
    BasicInformation,
    AcademicResults,
    VideoPresentation,
    RecommendationsTeamworks
  },
  state: {
    enviroments: {
      urlMock: "https://b76c9eed-53d0-453c-bb98-4462c449d325.mock.pstmn.io",
      urlDev: process.env.VUE_APP_URL_MIDDLE,
      urlDOM: process.env.VUE_APP_REDIRECT_URI
    },
    step: {
      firstStep: true,
      step5OK: false,
      step1Completed: false,
      step2Completed: false,
      step3Completed: false,
      step4Completed: false,
      step_admission: 0,
      step1PhotoCompleted: false
    },
    idAdmission: null,
    consent_form_id : process.env.VUE_APP_CONSENT_FORM_ID,
    lockApply:false
  },
  mutations: {
    setIdAdmission(state, data) {
      state.idAdmission = data;
      sessionStorage.setItem("idAdmission", data);
    },
    setLock(state, data) {
      state.lockApply = data;
    }
  },
  actions: {
  }
})
