export default {
  namespaced: true,
  state: {
    status: false,
    parent: [],
    student: {}
  },
  mutations: {
    setParent(state, data) {
      state.parent = data
    },
    setStudent(state, data) {
      state.student = data
    },
    resetData(state){
      state.parent = [];
      state.student = {};
    }
  },
  actions: {
  },
}